$font-sans-serif: "Cooper Hewitt", sans-serif;
$font-serif: "freight-text-pro", serif;
$font-size-default: 1.125rem;
$line-height-default: 1.4;

/*----------------------------------------------------------------------
  COLORS
----------------------------------------------------------------------*/
/* Site colors */
$aqua-blue: #87d1d4;
$blue-sky: #6aaae4;
$coral: #ee907b;
$deep-rose: #c7868d;
$forest-green: #3d5b58;
$golden-yellow: #fcd672;
$green-xlt: #f0f5f3;
$grey-md: #686868;
$grey-lt: #e7e7e7;
$onyx-black: #24272a;
$mint-ish: #6cca98;
$moss-green: #99bcab;
$pale-rose: #eaccce;
$pink-orange: #ffa168;
$red: #de5642;
$red-orange: #ea725e;
$spring-green: #c2e76b;

/*----------------------------------------------------------------------
  MISC
----------------------------------------------------------------------*/
$box-shadow: 0 1px 6px rgba(103, 107, 110, 0.2);

/*----------------------------------------------------------------------
  FORMS
----------------------------------------------------------------------*/

/*----------------------------------------------------------------------
  BREAKPOINTS
----------------------------------------------------------------------*/
$small: 420px;
$phablet: 660px;
$tablet: 768px;
$laptop: 1024px;
$desktop: 1200px;
$fullsize: 1600px;

$header-breakpoint: $desktop;

/*--------------------------------------------------------------------
  MIXINS
--------------------------------------------------------------------*/
@mixin clearfix {
  &::after {
    clear: both;
    content: "";
    display: block;
  }
}
