@import "src/styles/variables.scss";
.wrapper {
  margin-bottom: 2 * $font-size-default;
  text-align: center;

  @media (min-width: $tablet) {
    margin-bottom: 4 * $font-size-default;
  }

  blockquote {
    position: relative;
    p {
      font-style: italic;
    }

    cite {
      font-family: $font-sans-serif;
      font-style: normal;

      span {
        display: inline-block;
      }

      span + span {
        &::before {
          content: "|";
          display: inline-block;
          font-weight: 100;
          padding: 0 0.5rem;
        }
      }
    }
  }

  .citeName {
    font-weight: 900;
  }

  .quoteMark {
    font-size: 6rem;
    font-weight: 900;
    line-height: 1;
    padding-right: 1rem;
    position: absolute;
    right: 100%;
    top: -1rem;
  }
}

.citeImage {
  background-position: center center;
  background-size: cover;
  border-radius: 5rem;
  display: inline-block;
  height: 10rem;
  width: 10rem;
}
