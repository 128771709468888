@import "src/styles/variables.scss";
.inner {
  margin-bottom: 4rem;

  @media (min-width: $tablet) {
    margin-bottom: 8rem;
  }

  h2 {
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
  }
}

.iconsContainer {
  @media (min-width: $tablet) {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
  }

  .iconItem {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;

    @media (min-width: $tablet) {
      flex: 1 0 50%;
    }

    & + .iconItem {
      margin-top: 1rem;
      @media (min-width: $tablet) {
        margin-top: 0;
      }
    }

    .icon {
      flex: 0 0 8rem;
      padding-right: 1rem;

      @media (min-width: $laptop) {
        flex: 0 0 12rem;
      }
    }

    img {
      display: block;
    }

    div {
      flex: 1;
      @media (min-width: $tablet) {
        padding-right: 2rem;
      }
    }
  }
}

.content {
  margin-top: 2rem;
}
