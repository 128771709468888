@import "src/styles/variables.scss";
#container {
  margin-bottom: 4rem;
}
#wrapper {
  border-bottom: 1px solid $grey-lt;
  border-top: 1px solid $grey-lt;
  margin-bottom: 2rem;
  padding: 2rem 0;

  h3 {
    text-transform: uppercase;
  }
}

#form {
  button {
    width: 100%;
  }
}

#searchResults {
  ol {
    list-style: none;
    margin: 0;

    @media (min-width: $phablet) {
      column-count: 2;
      column-gap: 2rem;
    }
  }
  li {
    font-weight: bold;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  #noneFound {
  }
}
