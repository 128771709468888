@import "src/styles/variables.scss";
.button {
  background: $coral;
  border: 1px solid $coral;
  color: #fff;
  display: inline-block;
  font-family: $font-sans-serif;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 1px;
  padding: 1.1rem 3.1rem 0.9rem 3.1rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s ease-out;

  &.small {
    font-size: 90%;
    padding: 0.65rem 1.3rem 0.3rem;
  }

  &:disabled,
  &[data-disabled="true"] {
    opacity: 0.5;
    pointer-events: none;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background: #fff;
    border-color: $onyx-black;
    color: $onyx-black;
  }

  &.aquaBlue {
    background-color: $aqua-blue;
    border-color: $aqua-blue;
  }
  &.blueSky {
    background-color: $blue-sky;
    border-color: $blue-sky;
  }
  &.buttonLink {
    background: transparent;
    border: none;
    color: $coral;
    padding-left: 0;
    padding-right: 0;

    &:hover {
      background: transparent;
      border: none;
      color: $coral;
      text-decoration: underline;
    }
  }
  &.coral {
    background-color: $coral;
    border-color: $coral;
  }
  &.deepRose {
    background-color: $deep-rose;
    border-color: $deep-rose;
  }
  &.forestGreen {
    background-color: $forest-green;
    border-color: $forest-green;
  }
  &.goldenYellow {
    background-color: $golden-yellow;
    border-color: $golden-yellow;
  }
  &.greyMd {
    background-color: $grey-md;
    border-color: $grey-md;
  }
  &.greyLt {
    background-color: $grey-lt;
    border-color: $grey-lt;
  }
  &.inlineLink,
  &.inlineWarningLink {
    background: transparent;
    border: none;
    color: $coral;
    display: inline;
    font: inherit;
    letter-spacing: inherit;
    padding: 0;
    text-transform: inherit;

    &:hover {
      background: transparent;
      border: none;
      color: $coral;
      text-decoration: underline;
    }
  }

  &.inlineWarningLink {
    color: $red;
    text-decoration: underline;
    &:hover {
      color: $red;
      text-decoration: underline;
    }
  }
  &.onyxBlack {
    background-color: $onyx-black;
    border-color: $onyx-black;

    &:hover {
      background: #fff;
      color: $onyx-black;
    }
  }
  &.mintIsh {
    background-color: $mint-ish;
    border-color: $mint-ish;
  }
  &.mossGreen {
    background-color: $moss-green;
    border-color: $moss-green;
  }
  &.paleRose {
    background-color: $pale-rose;
    border-color: $pale-rose;
  }
  &.pinkOrange {
    background-color: $pink-orange;
    border-color: $pink-orange;
  }
  &.red {
    background-color: $red;
    border-color: $red;
  }
  &.redOrange {
    background-color: $red-orange;
    border-color: $red-orange;
  }
  &.springGreen {
    background-color: $spring-green;
    border-color: $red-orange;
  }
  &.white {
    background-color: #fff;
    border-color: $onyx-black;
    color: $onyx-black;
    &:hover {
      background-color: $onyx-black;
      color: #fff;
    }
  }

  &.transparentBlack {
    background-color: transparent;
    border: 1px solid $onyx-black;
    color: $onyx-black;
    &:hover {
      background-color: $onyx-black;
      color: #fff;
    }
  }

  &.borderBlack {
    background-color: white;
    border: 1px solid $onyx-black;
    color: $onyx-black;
    &:hover {
      background-color: $onyx-black;
      color: #fff;
    }
  }

  &.transparentWhite {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    &:hover {
      background-color: #fff;
      color: $onyx-black;
    }
  }

  &.transparentMossGreen {
    background-color: transparent;
    border: 1px solid $moss-green;
    color: $moss-green;
    &:hover {
      background-color: $moss-green;
      color: #fff;
    }
  }
}
