@import "src/styles/variables.scss";
.wrapper {
  margin-bottom: 2 * $font-size-default;

  @media (min-width: $tablet) {
    margin-bottom: 4 * $font-size-default;
  }
}

.title {
  font-weight: 900;
  text-transform: uppercase;
}
